/* https://www.color-blindness.com/color-name-hue/ */
:root {
  --color-brand: #2935ff;
  --color-green: #28a745;
  --color-yellow: #ffc940;
  --color-red: #ff0000;
  --color-white: #fff;
  --color-light-grey: #f7f7f7;
  --color-grey: #d8d8d8;
  --color-dark-grey: #b2b2b2;
  --color-black: #2d2926;
  --color-pitch-black: #0c0c0c;
  --color-yellow2: #fedd8b;
  --color-sahara: #b68c21;
  --color-floral-white: #fffaea;
  --color-gainsboro: #e5e5e5;

  --theme-background: var(--color-white);
  --theme-background-secondary: var(--color-light-grey);
  --theme-text: var(--color-black);
  --theme-text-secondary: var(--color-dark-grey);
  --theme-border: var(--color-gainsboro);
  --theme-border-secondary: var(--color-grey);
  --theme-tooltip-background: var(--color-pitch-black);
  --theme-tooltip-text: var(--color-white);
  --theme-tooltip-shadow: rgba(0, 0, 0, 0.16);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus:not(:focus-visible) {
  outline: 0;
}

body.using-mouse :focus {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-text);
  background-color: var(--theme-background);
  font-size: 15px;
  line-height: 1.0666;
}

#root {
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  min-height: 100vh;
}

#root-notifications {
  z-index: 20;
  position: fixed;
  bottom: 80px;
  width: 100%;
  padding: 0 8px;
}

#root-notifications:empty {
  display: none;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 104px;
}

a {
  color: var(--color-brand);
  text-decoration: none;
}

.link {
  font: inherit;
  color: var(--color-brand);
  text-decoration: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: 0;
}

.link:hover {
  opacity: 0.8;
}

.container {
  padding: 0 40px;
}

.subtitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  max-width: 640px;
  margin: 0 auto 64px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 32px;
}

.title2 {
  margin: 16px 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.title3 {
  margin: 16px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.title4 {
  margin: 8px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
}
