.wrap {
  position: fixed;
  bottom: 24px;
  right: 24px;
  max-width: 320px;
  background: var(--theme-background);
  border: 1px solid var(--theme-border);
  border-radius: 8px;
  padding: 16px;
  line-height: 20px;
  box-shadow: 0 6px 10px -8px rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.buttonsWrap {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  margin-right: 16px;
}

.button:last-child {
  margin-right: 0;
}
