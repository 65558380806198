.table {
  width: 100%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  color: var(--theme-text);
}

.table th {
  text-align: left;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  padding: 16px;
  min-width: 90px;
  background: var(--theme-background);
}

.table th.alignRight,
.table td.alignRight {
  text-align: right;
  justify-content: flex-end;
}

.table td {
  font-size: 15px;
  line-height: 16px;
  padding: 20px 16px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.table thead th:first-of-type {
  border-radius: 8px 0 0 0;
}

.table thead th:last-of-type {
  border-radius: 0 8px 0 0;
}

.table tbody tr:nth-child(odd) td {
  background: var(--theme-background);
}

.table tbody tr:nth-child(even) td {
  background: var(--theme-background-secondary);
}

.table td:first-of-type {
  border-radius: 8px 0 0 8px;
}

.table td:last-of-type {
  border-radius: 0 8px 8px 0;
}

.table .rank {
  padding: 8px;
  background: var(--color-light-grey);
  border-radius: 4px;
  display: inline-block;
}

.table .creditScore {
  display: inline-flex;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.04em;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid var(--theme-border);
  background: var(--theme-background);
  color: var(--theme-text-secondary);
  text-decoration: none;
}

.table .creditScore.Poor {
  border: 1px solid var(--color-red);
  background: var(--theme-background);
  color: var(--color-red);
}

.table .creditScore.Good {
  border: 1px solid var(--color-yellow);
  background: var(--theme-background);
  color: var(--color-yellow);
}

.table .creditScore.Excellent {
  border: 1px solid var(--color-green);
  background: var(--theme-background);
  color: var(--color-green);
}

.table .entity {
  font-weight: 600;
}

.table .entitySecondary {
  color: var(--theme-text-secondary);
  margin-top: 4px;
}

.table .reachability {
  display: inline-flex;
}

.table .reachability.reachable::before,
.table .reachability.maintenance::before,
.table .reachability.unreachable::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  align-self: center;
}

.table .reachability.reachable {
  color: var(--color-green);
}
.table .reachability.reachable::before {
  background: var(--color-green);
}
.table .reachability.maintenance {
  color: var(--color-sahara);
}
.table .reachability.maintenance::before {
  background: var(--color-sahara);
}
.table .reachability.unreachable {
  color: var(--color-red);
}
.table .reachability.unreachable::before {
  background: var(--color-red);
}

.table .green {
  color: var(--color-green);
}

.table .yellow {
  color: var(--color-yellow);
}

.table .red {
  color: var(--color-red);
}

.table .gray {
  color: var(--theme-text-secondary);
}

.table .highlight {
  font-weight: 700;
  text-decoration: underline;
}
