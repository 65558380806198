.button {
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
}

.button:disabled {
  cursor: not-allowed !important;
  background-color: var(--color-grey) !important;
  color: var(--color-dark-grey) !important;
}

.button.button--primary,
.button.button--secondary {
  border-radius: 4px;
  border: 0;
  min-height: 40px;
  padding: 6px 24px 6px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 0.2px;
  transition: 200ms ease all;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  font-weight: 600;
}

.button.button--primary {
  background-color: var(--color-brand);
  color: #fff;
}

.button.button--primary:hover {
  background-color: #003fe3;
}

.button.button--primary:active {
  border-style: inset;
}

.button.button--primary:focus {
  box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border: 0;
}

.button.button--secondary {
  background-color: var(--color-light-grey);
  color: var(--color-pitch-black);
  box-shadow: none;
}

.button.button--secondary:hover {
  background-color: #eee;
}

.button.button--secondary:active {
  border-style: inset;
}

.button.button--secondary:focus {
  box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border: 0;
}
