.header {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.header.minerPanel {
  background: var(--theme-background-secondary);
}

.logoLink {
  display: inline-flex;
  text-decoration: none;
  margin-right: 64px;
  align-items: center;
  min-height: 40px;
}

.filrep {
  color: var(--theme-text);
  margin-left: 8px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
}

.nav {
  display: flex;
  align-items: center;
}

.secondaryNav {
  margin-left: auto;
}

.link {
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 32px;
  color: var(--theme-text-secondary);
}

.link:hover,
.link.active {
  color: var(--theme-text);
}

.link:last-of-type {
  margin-right: 0;
}

.loginLink {
  margin-right: 24px;
}

.loginLink:last-of-type {
  margin-right: 0;
}
